import React from 'react'
import moment from 'moment'
import MilestoneDateTag from '../../GoalsView/MilestoneDateTag'
import { BACKLOG_DATE_NUMERIC } from '../../TaskListView/Utils/TasksHelper'
import { translate } from '../../../i18n/TranslationService'

export default function DateTag({ completionMilestoneDate }) {
    const date =
        completionMilestoneDate === BACKLOG_DATE_NUMERIC ? translate('Someday') : moment(completionMilestoneDate)
    return date ? <MilestoneDateTag date={date} inDetailedView={true} /> : null
}
