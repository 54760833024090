import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import { colors } from '../../styles/global'
import DefaultAssistantAvatar from './DefaultAssistantAvatar'
import LastAssistantCommentContainer from './LastAssistantCommentContainer'
import DefaultAssistantData from './DefaultAssistantData'
import MyDayBotLineButtons from './MyDayBotLineButtons'

export default function MyDayBotLine() {
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)

    return (
        <View style={localStyles.container}>
            <View style={localStyles.leftArea}>
                <DefaultAssistantAvatar />
                {!isMiddleScreen && <DefaultAssistantData />}
            </View>
            <View style={localStyles.rightArea}>
                <LastAssistantCommentContainer />
            </View>
            <MyDayBotLineButtons />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colors.Grey100,
        marginTop: 16,
        borderRadius: 4,
        //alignItems: 'center',
        height: 128,
        paddingLeft: 10,
        paddingRight: 16,
        paddingTop: 14,
        paddingBottom: 12,
    },
    leftArea: {
        flexDirection: 'row',
    },
    rightArea: {
        alignContent: 'flex-start',
        flex: 1,
        marginLeft: 16,
    },
})
