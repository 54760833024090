import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ImageBackground, Image, Text, ScrollView } from 'react-native'

import Icon from '../Icon'
import styles from '../styles/global'
import LogInButton from '../UIControls/LogInButton'
import URLSystem, { URL_LOGIN } from '../../URLSystem/URLSystem'
import Colors from '../../Themes/Colors'
import {
    getProjectData,
    logEvent,
    loginWithGoogleWebAnonymously,
    runHttpsCallableFunction,
} from '../../utils/backends/firestore'
import { useSelector, useDispatch } from 'react-redux'
import { setInitialUrl, setNavigationRoute } from '../../redux/actions'

const LOADING_LOGIN = 'LOADING_LOGIN'
const NORMAL_LOGIN = 'NORMAL_LOGIN'
const GUIDE_LOGIN = 'GUIDE_LOGIN'

export default function LoginScreenContent() {
    const dispatch = useDispatch()
    const initialUrl = useSelector(state => state.initialUrl)
    const [templateTitle, setTemplateTitle] = useState('')
    const [templateImage, setTemplateImage] = useState('')
    const [loginType, setLoginType] = useState(
        window.location.pathname.startsWith('/login') || window.location === '/' ? NORMAL_LOGIN : LOADING_LOGIN
    )

    const getProjectIdFromUrl = url => {
        return url.split('/')[2]
    }

    const selectPictureToShow = async url => {
        await loginWithGoogleWebAnonymously()

        const projectId = getProjectIdFromUrl(url)

        if (projectId) {
            const promises = []
            promises.push(getProjectData(projectId))
            promises.push(
                runHttpsCallableFunction('getTemplateProductDataSecondGen', {
                    templateId: projectId,
                })
            )
            const [project, templateProductData] = await Promise.all(promises)

            const isTemplate = project && project.isTemplate
            if (isTemplate) {
                setTemplateTitle(templateProductData?.data?.name || 'Sign up/in with your personal email')
                setTemplateImage(
                    templateProductData?.data?.picture || require('../../web/images/illustrations/guidesLogin.png')
                )
            }
            setLoginType(isTemplate ? GUIDE_LOGIN : NORMAL_LOGIN)
        } else {
            setLoginType(NORMAL_LOGIN)
        }
    }

    const writeBrowserURL = () => {
        URLSystem.push(URL_LOGIN)
    }

    useEffect(() => {
        let url = ''
        if (initialUrl === '/') {
            const { pathname } = window.location
            url = pathname.startsWith('/login') || pathname === '/' ? '/projects/tasks/open' : pathname
            dispatch(setInitialUrl(url))
        }
        url ? selectPictureToShow(url) : setLoginType(NORMAL_LOGIN)
    }, [])

    useEffect(() => {
        writeBrowserURL()
        dispatch(setNavigationRoute('LoginScreen'))
        logEvent('login_page')
    }, [])

    return (
        <ImageBackground source={require('../../web/images/illustrations/LoginBg.svg')} style={localStyles.container}>
            <ScrollView showsVerticalScrollIndicator={false} style={localStyles.scrollContainer}>
                <View style={localStyles.scrollSubContainer}>
                    <a href="/" style={{ top: 64, textDecoration: 'none' }}>
                        <View style={{ flex: 1, flexDirection: 'row' }}>
                            <Icon size={24} name={'logo'} color={Colors.Primary100} />
                            <Icon style={localStyles.logoText} size={24} name={'logo-name'} color={Colors.Primary100} />
                        </View>
                    </a>
                    {loginType === NORMAL_LOGIN && (
                        <Image
                            source={{ uri: require(`../../web/images/illustrations/PersonajeEnseñando.png`) }}
                            style={{ width: 362 * 0.75, height: 470 * 0.75 }}
                            width={362}
                            height={470}
                        />
                    )}
                    {loginType === GUIDE_LOGIN && (
                        <Image
                            source={{ uri: templateImage }}
                            style={{ marginTop: 16, width: 600 * 0.51, height: 692 * 0.51 }}
                            width={600}
                            height={692}
                        />
                    )}

                    {loginType === NORMAL_LOGIN && (
                        <Text style={localStyles.title}>Sign in with your personal email to access Alldone</Text>
                    )}
                    {loginType === GUIDE_LOGIN && <Text style={localStyles.title}>{templateTitle}</Text>}
                    <LogInButton />
                    <Text style={localStyles.subtitle}>
                        Alldone helps you to achieve your goals in life. When you sign in you accept the{' '}
                        <a
                            href="https://alldone.app/privacy"
                            target={'_blank'}
                            style={{ color: Colors.Primary100, textDecoration: 'none' }}
                        >
                            Privacy Policy
                        </a>{' '}
                        and the{' '}
                        <a
                            href="https://alldone.app/terms"
                            target={'_blank'}
                            style={{ color: Colors.Primary100, textDecoration: 'none' }}
                        >
                            Terms of Service
                        </a>{' '}
                        and want to login with your Google account
                    </Text>
                </View>
            </ScrollView>
        </ImageBackground>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    scrollContainer: {
        flex: 1,
        width: '100%',
    },
    scrollSubContainer: {
        flex: 1,
        paddingTop: 64,
        paddingBottom: 32,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        ...styles.title4,
        textAlign: 'center',
        color: Colors.Text02,
        padding: 16,
        maxWidth: 572,
    },
    subtitle: {
        ...styles.body1,
        textAlign: 'center',
        color: Colors.Text01,
        paddingTop: 32,
        paddingBottom: 16,
        maxWidth: 548,
        paddingHorizontal: 16,
    },
    logoText: {
        marginLeft: 9,
    },
})
