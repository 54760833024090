import store from '../../redux/store'
import TasksHelper from '../TaskListView/Utils/TasksHelper'

export const UNLOCK_GOAL_COST = 80

export function checkIfUserIsGuideAdmin(user) {
    return user.realTemplateProjectIds.length > 0
}

export function objectIsLockedForUser(projectId, unlockedKeysByGuides, lockKey, ownerId) {
    if (!lockKey) return false

    const { loggedUser, administratorUser } = store.getState()
    if (loggedUser.isAnonymous) return true

    const isCreator = administratorUser.uid === loggedUser.uid || loggedUser.realTemplateProjectIds.includes(projectId)
    if (isCreator) {
        const owner = TasksHelper.getUserInProject(projectId, ownerId)
        if (owner) {
            return !owner.unlockedKeysByGuides[projectId] || !owner.unlockedKeysByGuides[projectId].includes(lockKey)
        }
    }

    return !isCreator && (!unlockedKeysByGuides[projectId] || !unlockedKeysByGuides[projectId].includes(lockKey))
}
