import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'

import { createBotQuickTopic } from '../../utils/assistantHelper'
import AssistantAvatar from '../AdminPanel/Assistants/AssistantAvatar'
import { getDefaultAssistantInProjectByIndex } from '../AdminPanel/Assistants/assistantsHelper'

export default function AssistantQuickTopic({ containerStyle }) {
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)

    const assistant = getDefaultAssistantInProjectByIndex(selectedProjectIndex)

    const createTopic = () => {
        createBotQuickTopic(assistant)
    }

    return (
        <TouchableOpacity style={[localStyles.container, containerStyle]} onPress={createTopic} accessible={false}>
            <AssistantAvatar photoURL={assistant.photoURL} assistantId={assistant.uid} size={24} />
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 28,
        width: 28,
    },
})
