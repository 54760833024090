import * as React from 'react'

function PushNotificationsMobile(props) {
    return (
        <svg width={256} height={256} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M52.583 61.601a5 5 0 015.793-4.056l21.666 3.82a5 5 0 014.055 5.793l-8.682 49.24a5 5 0 01-5.792 4.056l-21.666-3.82a5 5 0 01-4.056-5.792l8.682-49.24z"
                fill="#FFDBF9"
            />
            <path
                d="M57.515 60.035a5 5 0 016.409-2.988l20.673 7.524a5 5 0 012.988 6.409l-17.1 46.984a5 5 0 01-6.41 2.989l-20.672-7.525a5 5 0 01-2.989-6.408l17.101-46.985z"
                fill="#C7F5E5"
            />
            <path
                d="M55.015 60.71a5 5 0 016.124-3.535l21.25 5.694a5 5 0 013.535 6.124l-12.94 48.296a5.001 5.001 0 01-6.124 3.536l-21.25-5.694a5 5 0 01-3.536-6.124l12.94-48.296z"
                fill="#A3D1FF"
            />
            <rect x={57.725} y={58.331} width={28} height={56} rx={3} transform="rotate(15 57.725 58.33)" fill="#fff" />
            <path
                d="M46.526 103.988a2 2 0 012.45-1.414l21.25 5.694a2 2 0 011.414 2.449l-1.553 5.796a2 2 0 01-2.45 1.414l-21.25-5.694a2 2 0 01-1.414-2.45l1.553-5.795z"
                fill="#152560"
            />
            <path
                d="M56.428 106.641a.75.75 0 01.919-.531l9.659 2.589a.75.75 0 11-.388 1.448l-9.66-2.588a.75.75 0 01-.53-.918zM55.65 109.538a.75.75 0 01.92-.53l10.625 2.847a.75.75 0 01-.389 1.449l-10.625-2.847a.75.75 0 01-.53-.919zM55.792 111.906a.75.75 0 10-.388 1.449l9.659 2.588a.75.75 0 10.388-1.449l-9.659-2.588z"
                fill="#1A3289"
            />
            <rect
                x={47.75}
                y={103.281}
                width={8}
                height={8}
                rx={1}
                transform="rotate(15 47.75 103.281)"
                fill="#FEF1D7"
            />
            <path d="M51.441 110.606a.47.47 0 11-.465.073l-.658-4.175.465-.074.658 4.176z" fill="#F58E0A" />
            <path
                d="M49.21 105.812a1.882 1.882 0 00-.688 2.571c.938 1.625.536 1.864.534 1.866l-.102.058a.354.354 0 00.352.612l4.28-2.471a.353.353 0 10-.353-.611l-.102.059c-.01.005-.418.217-1.349-1.395a1.883 1.883 0 00-2.571-.689z"
                fill="#FDCB6E"
            />
            <path
                d="M48.685 105.843a.47.47 0 11.815-.47l.148.256c-.15.042-.297.102-.438.183-.159.273-.36 1.023.11 1.838.682 1.18.765 1.568.823 1.843l.025.113 3.065-1.769a.352.352 0 11.353.611l-4.28 2.471a.353.353 0 11-.353-.611l.004-.002-.004.001.102-.058c.003-.002.404-.241-.534-1.866a1.89 1.89 0 01.311-2.287v.001l-.147-.254z"
                fill="#FCB127"
            />
            <circle cx={55.477} cy={105.351} r={2} transform="rotate(15 55.477 105.351)" fill="#FF7043" />
            <path
                d="M55.842 103.988a.32.32 0 00-.392.227l-.29 1.084a.32.32 0 00.618.166l.29-1.085a.32.32 0 00-.226-.392zM55.3 106.014a.32.32 0 00-.392.227.32.32 0 10.392-.227z"
                fill="#fff"
            />
            <path
                d="M56.946 61.228a3 3 0 013.675-2.121L81.87 64.8a3 3 0 012.121 3.674l-.776 2.898-27.046-7.247.776-2.898z"
                fill="#0D55CF"
            />
            <path
                d="M56.678 69.956a1.5 1.5 0 011.838-1.06L78.8 74.33a1.5 1.5 0 11-.776 2.898l-20.285-5.436a1.5 1.5 0 01-1.06-1.837zM54.866 76.718a1.5 1.5 0 011.837-1.06l20.284 5.434a1.5 1.5 0 11-.776 2.898l-20.284-5.435a1.5 1.5 0 01-1.061-1.837zM54.892 82.418a1.5 1.5 0 00-.776 2.898L74.4 90.751a1.5 1.5 0 10.777-2.897l-20.285-5.436zM51.243 90.24a1.5 1.5 0 011.837-1.06l20.284 5.435a1.5 1.5 0 01-.776 2.898l-20.284-5.435a1.5 1.5 0 01-1.061-1.837zM51.27 95.942a1.5 1.5 0 00-.777 2.897l20.284 5.435a1.5 1.5 0 10.777-2.897l-20.285-5.435z"
                fill="#E7ECEF"
            />
            <path
                d="M94.144 60.164a15.068 15.068 0 00-9.15-7.02 1 1 0 01.518-1.932A17.069 17.069 0 0197.58 72.116a1 1 0 11-1.932-.517 15.068 15.068 0 00-1.505-11.435z"
                fill="#09D693"
            />
            <path
                d="M84.048 56.677a11.41 11.41 0 018.07 13.975 1 1 0 101.93.518 13.41 13.41 0 00-9.482-16.425 1 1 0 10-.518 1.932z"
                fill="#09D693"
            />
            <path
                d="M83.103 60.21a7.753 7.753 0 015.482 9.495 1 1 0 001.932.518 9.753 9.753 0 00-6.897-11.945 1 1 0 10-.517 1.932z"
                fill="#09D693"
            />
            <circle cx={70.471} cy={64.852} r={1} transform="rotate(15 70.47 64.852)" fill="#5AACFF" />
            <path
                opacity={0.5}
                d="M15.894 6.448a6 6 0 00-6.306 5.678L8.228 38.09a6 6 0 005.677 6.306l41.289 2.164a2 2 0 011.365.641l4.92 5.333a2 2 0 002.79.146l5.452-4.79a2 2 0 011.424-.494l41.287 2.164a6 6 0 006.306-5.678l1.36-25.964a6 6 0 00-5.677-6.306L15.894 6.448z"
                fill="#05F"
            />
            <path
                opacity={0.5}
                d="M13.8 11.6a6 6 0 00-5.678 6.306l1.36 25.965a6 6 0 006.306 5.677l41.086-2.153a2 2 0 011.427.497l5.417 4.771a2 2 0 002.805-.16l4.773-5.28a2 2 0 011.38-.656l41.203-2.16a6 6 0 005.678-6.305l-1.361-25.964a6 6 0 00-6.305-5.678L13.799 11.6z"
                fill="#80DEEA"
            />
            <path
                d="M16 9a6 6 0 00-6 6v26a6 6 0 006 6h40.172a2 2 0 011.414.586l5 5a2 2 0 002.828 0l5-5A2 2 0 0171.828 47H112a6 6 0 006-6V15a6 6 0 00-6-6H16z"
                fill="#152560"
            />
            <path
                d="M52 17a3 3 0 013-3h32a3 3 0 110 6H55a3 3 0 01-3-3zM52 28a3 3 0 013-3h54a3 3 0 110 6H55a3 3 0 01-3-3zM55 36a3 3 0 100 6h39a3 3 0 100-6H55z"
                fill="#1A3289"
            />
            <rect x={12} y={11} width={34} height={34} rx={4} fill="#FEF1D7" />
            <path d="M35.21 37.01a2 2 0 11-1.828.813l-7.296-16.417 1.828-.812L35.21 37.01z" fill="#F58E0A" />
            <path
                d="M20.782 19.783c-3.14 3.108-3.108 8.173 0 11.314 5.638 5.638 4.253 7.06 4.243 7.07l-.354.354a1.5 1.5 0 102.121 2.121l14.85-14.849a1.5 1.5 0 10-2.121-2.121l-.355.354c-.031.03-1.477 1.351-7.07-4.243-3.108-3.14-8.174-3.108-11.314 0z"
                fill="#FDCB6E"
            />
            <path
                d="M18.66 20.49a2 2 0 012.828-2.828l.891.89a8.122 8.122 0 00-1.597 1.231c-.354 1.297-.353 4.596 2.476 7.424 4.093 4.094 4.86 5.598 5.403 6.663.077.152.15.295.228.435l10.633-10.633a1.5 1.5 0 112.121 2.121l-14.85 14.85a1.5 1.5 0 11-2.12-2.122l.011-.011-.012.01.354-.353c.01-.01 1.394-1.432-4.243-7.07-2.623-2.65-3.056-6.671-1.24-9.73l-.002.004-.881-.881z"
                fill="#FCB127"
            />
            <circle cx={43} cy={14} r={6} fill="#FF7043" />
            <path d="M44.002 11a1 1 0 00-2 0v3a1 1 0 102 0v-3zM43.002 18a1 1 0 100-2 1 1 0 000 2z" fill="#fff" />
        </svg>
    )
}

export default PushNotificationsMobile
