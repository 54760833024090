import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-native'

import ShowMoreButton from '../../UIControls/ShowMoreButton'
import { contractOpenTasks, updateOpTasks, watchOpenTasks } from '../../../utils/backends/openTasks'
import { setLaterTasksExpanded, setSomedayTasksExpanded } from '../../../redux/actions'
import store from '../../../redux/store'

export default function AllProjectsShowMoreButtonContainer({
    projectIds,
    setProjectsHaveTasksInFirstDay,
    dontShowHideButton,
}) {
    const dispatch = useDispatch()
    const loggedUserId = useSelector(state => state.loggedUser.uid)
    const thereAreLaterOpenTasks = useSelector(state => state.thereAreLaterOpenTasks)
    const thereAreLaterEmptyGoals = useSelector(state => state.thereAreLaterEmptyGoals)
    const thereAreSomedayOpenTasks = useSelector(state => state.thereAreSomedayOpenTasks)
    const thereAreSomedayEmptyGoals = useSelector(state => state.thereAreSomedayEmptyGoals)
    const laterTasksExpanded = useSelector(state => state.laterTasksExpanded)
    const somedayTasksExpanded = useSelector(state => state.somedayTasksExpanded)

    const [thereAreLaterOpenTasksInAnyProject, setThereAreLaterOpenTasksInAnyProject] = useState(false)
    const [thereAreLaterEmptyGoalsInAnyProject, setThereAreLaterEmptyGoalsInAnyProject] = useState(false)
    const [thereAreSomedayOpenTasksInAnyProject, setThereAreSomedayOpenTasksInAnyProject] = useState(false)
    const [thereAreSomedayEmptyGoalsInAnyProject, setThereAreSomedayEmptyGoalsInAnyProject] = useState(false)

    const thereAreLaterObjects = thereAreLaterOpenTasksInAnyProject || thereAreLaterEmptyGoalsInAnyProject
    const thereAreSomedayObjects = thereAreSomedayOpenTasksInAnyProject || thereAreSomedayEmptyGoalsInAnyProject

    const thereAreLaterOrSomedayObjects = thereAreLaterObjects || thereAreSomedayObjects

    const expandTasks = () => {
        if (!laterTasksExpanded) {
            if (thereAreLaterObjects) {
                dispatch(setLaterTasksExpanded(true))
            } else if (thereAreSomedayObjects) {
                dispatch([setLaterTasksExpanded(true), setSomedayTasksExpanded(true)])
            }
        } else {
            if (thereAreSomedayObjects) {
                dispatch([setLaterTasksExpanded(true), setSomedayTasksExpanded(true)])
            }
        }

        projectIds.forEach(projectId => {
            const instanceKey = projectId + loggedUserId

            const updateTaks = (initialTasks, initialLoadingInOpenTasks) => {
                updateOpTasks(
                    projectId,
                    instanceKey,
                    initialTasks,
                    initialLoadingInOpenTasks,
                    setProjectsHaveTasksInFirstDay,
                    false
                )
            }

            const showSomedayObjects =
                thereAreSomedayObjects &&
                ((!laterTasksExpanded && !thereAreLaterObjects) || (laterTasksExpanded && !somedayTasksExpanded))
            watchOpenTasks(projectId, updateTaks, true, showSomedayObjects, true, instanceKey)
        })
    }

    const contractTasks = () => {
        projectIds.forEach(projectId => {
            const instanceKey = projectId + loggedUserId

            const openTasksStore = store.getState().openTasksStore[instanceKey]
            const openTasks = openTasksStore ? openTasksStore : []

            const updateTaks = (initialTasks, initialLoadingInOpenTasks) => {
                updateOpTasks(
                    projectId,
                    instanceKey,
                    initialTasks,
                    initialLoadingInOpenTasks,
                    setProjectsHaveTasksInFirstDay,
                    false
                )
            }

            contractOpenTasks(projectId, instanceKey, openTasks, updateTaks)
        })
    }

    const checkIfThereAreAnyLaterOrSomedayObject = mapByProjects => {
        const values = Object.values(mapByProjects)
        let thereAre = false
        for (let i = 0; i < values.length; i++) {
            if (values[i]) {
                thereAre = true
                break
            }
        }
        return thereAre
    }

    useEffect(() => {
        setThereAreLaterOpenTasksInAnyProject(checkIfThereAreAnyLaterOrSomedayObject(thereAreLaterOpenTasks))
    }, [thereAreLaterOpenTasks])

    useEffect(() => {
        setThereAreLaterEmptyGoalsInAnyProject(checkIfThereAreAnyLaterOrSomedayObject(thereAreLaterEmptyGoals))
    }, [thereAreLaterEmptyGoals])

    useEffect(() => {
        setThereAreSomedayOpenTasksInAnyProject(checkIfThereAreAnyLaterOrSomedayObject(thereAreSomedayOpenTasks))
    }, [thereAreSomedayOpenTasks])

    useEffect(() => {
        setThereAreSomedayEmptyGoalsInAnyProject(checkIfThereAreAnyLaterOrSomedayObject(thereAreSomedayEmptyGoals))
    }, [thereAreSomedayEmptyGoals])

    return thereAreLaterOrSomedayObjects ? (
        <View style={{ flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
            {((!laterTasksExpanded && thereAreLaterObjects) || (!somedayTasksExpanded && thereAreSomedayObjects)) && (
                <ShowMoreButton
                    expanded={false}
                    expand={expandTasks}
                    expandText={'later tasks across all projects'}
                    style={{
                        flex: 0,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 8,
                        marginHorizontal: 10,
                        opacity: 0.5,
                    }}
                />
            )}
            {laterTasksExpanded && !dontShowHideButton && (
                <ShowMoreButton
                    expanded={true}
                    contract={contractTasks}
                    contractText={'hide later tasks across all projects'}
                    style={{
                        flex: 0,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        marginTop: 8,
                        marginHorizontal: 10,
                        opacity: 0.5,
                    }}
                />
            )}
        </View>
    ) : null
}
