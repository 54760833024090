import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import CalendarSection from './CalendarSection'
import { CALENDAR_TASK_INDEX } from '../../../utils/backends/openTasks'

export default function CalendarSectionContainer({ projectId, dateIndex, instanceKey, isActiveOrganizeMode }) {
    const calendarTasks = useSelector(
        state => state.filteredOpenTasksStore[instanceKey][dateIndex][CALENDAR_TASK_INDEX]
    )

    const showTasks =
        calendarTasks[0][1].filter(
            item =>
                moment(item.calendarData.start.dateTime || item.calendarData.start.date).format('DDMMYYYY') ===
                moment().format('DDMMYYYY')
        ).length > 0

    return (
        <>
            {showTasks && (
                <CalendarSection
                    projectId={projectId}
                    calendarEvents={calendarTasks}
                    dateIndex={dateIndex}
                    isActiveOrganizeMode={isActiveOrganizeMode}
                    instanceKey={instanceKey}
                />
            )}
        </>
    )
}
