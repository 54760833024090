import React from 'react'

import { dismissAllPopups } from '../../../utils/HelperFunctions'
import DismissibleItem from '../../UIComponents/DismissibleItem'
import EditAssistant from './EditAssistant'
import AssistantPresentation from './AssistantPresentation'

export default function AssistantItem({ projectId, assistant, setDismissibleRefs, openEdition, closeEdition, refKey }) {
    const setRef = ref => {
        setDismissibleRefs(ref, refKey)
    }

    const openEditionMode = () => {
        openEdition(refKey)
        setTimeout(() => {
            dismissAllPopups()
        })
    }

    const closeEditionMode = () => {
        closeEdition(refKey)
    }

    return (
        <DismissibleItem
            ref={setRef}
            defaultComponent={<AssistantPresentation projectId={projectId} assistant={assistant} />}
            modalComponent={
                <EditAssistant
                    projectId={projectId}
                    refKey={refKey}
                    adding={false}
                    assistant={assistant}
                    onCancelAction={closeEditionMode}
                />
            }
        />
    )
}
