import React from 'react'
import { StyleSheet, View, Text } from 'react-native'

import styles, { colors } from '../../../styles/global'
import AddPreConfigTaskWrapper from './AddPreConfigTaskWrapper'
import Icon from '../../../Icon'
import { TASK_TYPE_PROMPT } from '../../../UIComponents/FloatModals/PreConfigTaskModal/TaskModal'

export default function PreConfigTaskItem({ disabled, projectId, task, assistantId }) {
    const { name, type } = task
    return (
        <View style={localStyles.container}>
            <Icon
                name={type === TASK_TYPE_PROMPT ? 'cpu' : 'bookmark'}
                size={24}
                color={colors.Text03}
                style={localStyles.icon}
            />
            <Text style={localStyles.text}>{name}</Text>
            <View style={{ marginLeft: 'auto' }}>
                <AddPreConfigTaskWrapper
                    disabled={disabled}
                    projectId={projectId}
                    assistantId={assistantId}
                    task={task}
                    adding={false}
                />
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        maxHeight: 56,
        minHeight: 56,
        height: 56,
        paddingLeft: 8,
        paddingVertical: 8,
        alignItems: 'center',
    },
    icon: {
        marginRight: 8,
    },
    text: {
        ...styles.subtitle2,
        color: colors.Text03,
    },
    button: {
        marginHorizontal: 0,
    },
})
