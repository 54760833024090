import React, { useEffect, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import Header from './Header'
import URLsProjects, { URL_PROJECT_DETAILS_ASSISTANTS } from '../../../URLSystem/Projects/URLsProjects'
import { DV_TAB_PROJECT_ASSISTANTS } from '../../../utils/TabNavigationConstants'
import AssistantsList from '../../AdminPanel/Assistants/AssistantsList'
import store from '../../../redux/store'
import { resetLoadingData } from '../../../redux/actions'
import AddAssistantWrapper from './AddAssistantWrapper'

export default function Assistants({ project, accessGranted }) {
    const selectedTab = useSelector(state => state.selectedNavItem)
    const globalAssistants = useSelector(state => state.globalAssistants)
    const assistants = useSelector(state => state.projectAssistants[project.id])
    const dismissibleRefs = useRef({})

    const setDismissibleRefs = (ref, dismissibleId) => {
        if (ref) dismissibleRefs.current[dismissibleId] = ref
    }

    const openEdition = dismissibleId => {
        const { showFloatPopup } = store.getState()
        if (showFloatPopup === 0) closeAllEdition()
        if (!checkIfAnyDismissibleIsOpen()) dismissibleRefs.current[dismissibleId].openModal()
    }

    const closeEdition = dismissibleId => {
        dismissibleRefs.current[dismissibleId].closeModal()
    }

    const closeAllEdition = () => {
        for (let dismissibleId in dismissibleRefs.current) {
            if (dismissibleRefs.current[dismissibleId].modalIsVisible()) closeEdition(dismissibleId)
        }
    }

    const checkIfAnyDismissibleIsOpen = () => {
        for (let dismissibleId in dismissibleRefs.current) {
            if (dismissibleRefs.current[dismissibleId].modalIsVisible()) return true
        }
        return false
    }

    const writeBrowserURL = () => {
        if (selectedTab === DV_TAB_PROJECT_ASSISTANTS) {
            const data = { projectId: project.id }
            URLsProjects.push(URL_PROJECT_DETAILS_ASSISTANTS, data, project.id)
        }
    }

    useEffect(() => {
        writeBrowserURL()
    }, [])

    useEffect(() => {
        return () => {
            store.dispatch(resetLoadingData())
        }
    }, [])

    const assistantsToShow = [
        ...globalAssistants.filter(assistant => project.globalAssistantIds.includes(assistant.uid)),
        ...assistants,
    ]

    return (
        <View style={localStyles.container}>
            <Header amount={assistantsToShow.length} />
            {accessGranted && <AddAssistantWrapper project={project} />}
            <AssistantsList
                projectId={project.id}
                assistants={assistantsToShow}
                setDismissibleRefs={setDismissibleRefs}
                openEdition={openEdition}
                closeEdition={closeEdition}
            />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
