import {
    URL_FEEDS_FOLLOWED,
    URL_FEEDS_NOT_FOLLOWED,
    URL_LOGIN,
    URL_LOGOUT,
    URL_PRIVATE_RESOURCE,
    URL_PROJECT_FEEDS_FOLLOWED,
    URL_PROJECT_FEEDS_NOT_FOLLOWED,
} from './URLSystem'
import SettingsHelper from '../components/SettingsView/SettingsHelper'
import TasksHelper from '../components/TaskListView/Utils/TasksHelper'

export const URL_NOT_MATCH = 'NOT_MATCH'

class URLSystemTrigger {
    static getRegexList = () => {
        return {
            [URL_FEEDS_FOLLOWED]: new RegExp('^/updates/followed$'),
            [URL_FEEDS_NOT_FOLLOWED]: new RegExp('^/updates/all$'),
            [URL_PROJECT_FEEDS_FOLLOWED]: new RegExp(
                '^/projects/(?<projectId>[\\w-]+)/user/(?<userId>[\\w-]+)/updates/followed$'
            ),
            [URL_PROJECT_FEEDS_NOT_FOLLOWED]: new RegExp(
                '^/projects/(?<projectId>[\\w-]+)/user/(?<userId>[\\w-]+)/updates/all$'
            ),
            [URL_PRIVATE_RESOURCE]: /^\/private-resource$/,
            [URL_LOGOUT]: /^\/logout$/,
            [URL_LOGIN]: /^\/login$/,
        }
    }

    static match = pathname => {
        const regexList = URLSystemTrigger.getRegexList()

        for (let key in regexList) {
            const matchObj = pathname.match(regexList[key])

            if (matchObj) {
                return { key: key, matches: matchObj }
            }
        }

        return URL_NOT_MATCH
    }

    static trigger = (navigation, pathname) => {
        const matchedObj = URLSystemTrigger.match(pathname)
        const params = matchedObj.matches.groups

        // This Switch will have CASEs as elements have the "regexList" const
        switch (matchedObj.key) {
            case URL_FEEDS_FOLLOWED:
                return SettingsHelper.processURLFeeds(navigation, URL_FEEDS_FOLLOWED)
            case URL_FEEDS_NOT_FOLLOWED:
                return SettingsHelper.processURLFeeds(navigation, URL_FEEDS_NOT_FOLLOWED)
            case URL_PROJECT_FEEDS_FOLLOWED:
                return SettingsHelper.processURLFeeds(
                    navigation,
                    URL_PROJECT_FEEDS_FOLLOWED,
                    params.projectId,
                    params.userId
                )
            case URL_PROJECT_FEEDS_NOT_FOLLOWED:
                return SettingsHelper.processURLFeeds(
                    navigation,
                    URL_PROJECT_FEEDS_NOT_FOLLOWED,
                    params.projectId,
                    params.userId
                )
            case URL_PRIVATE_RESOURCE:
                return SettingsHelper.processURLPrivateResource(navigation)
            case URL_LOGIN:
                return TasksHelper.processURLAllProjectsTasks(navigation)
        }
    }
}

export default URLSystemTrigger
