import * as React from 'react'

const SvgComponent = props => (
    <svg width={props.width} height={props.height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M99.8751 200C95.5001 200 99.8751 200 99.8751 200C99.8751 200 99.8751 160 99.8751 99.949C99.8751 40 99.8751 0 99.8751 0C99.8751 0 95.7501 0 99.8751 0C157 0 199.75 48.75 199.75 99.75C199.75 152.5 156.5 200 99.8751 200Z"
            fill="#FF9F00"
        />
        <path
            d="M200 100C200 155.228 155.228 200 100 200C44.772 200 0 155.228 0 100C0 44.772 44.772 0 100 0C155.228 0 200 44.772 200 100Z"
            fill={`url(#paint0_linear_2582_246${props.id})`}
        />
        <path
            d="M180 100C180 144.183 144.183 180 100 180C55.817 180 20 144.183 20 100C20 55.817 55.817 20 100 20C144.183 20 180 55.817 180 100Z"
            fill="#FFBB00"
        />
        <path
            d="M172.5 100C172.5 140.041 140.041 172.5 100 172.5C59.959 172.5 27.5 140.041 27.5 100C27.5 59.959 59.959 27.5 100 27.5C140.041 27.5 172.5 59.959 172.5 100Z"
            fill={`url(#paint1_linear_2582_246${props.id})`}
        />
        <path
            d="M103.631 53.9797L115.62 78.2728C116.393 79.8398 117.888 80.9268 119.618 81.1777L146.428 85.0737C148.606 85.3897 149.475 88.0658 147.899 89.6017L128.5 108.512C127.249 109.732 126.678 111.489 126.973 113.212L131.552 139.913C131.924 142.082 129.648 143.736 127.7 142.712L103.721 130.105C102.174 129.292 100.326 129.292 98.7789 130.105L74.7999 142.712C72.8519 143.736 70.5759 142.082 70.9479 139.913L75.5269 113.212C75.8219 111.489 75.2509 109.732 73.9999 108.512L54.6009 89.6017C53.0249 88.0658 53.8939 85.3897 56.0719 85.0737L82.8819 81.1777C84.6119 80.9268 86.1069 79.8398 86.8799 78.2728L98.8689 53.9797C99.8429 52.0068 102.657 52.0068 103.631 53.9797Z"
            fill={`url(#paint2_linear_2582_246${props.id})`}
        />
        <path
            d="M118.12 80.7729C118.894 82.3399 120.389 83.4269 122.119 83.6779L148.693 87.5399C148.615 86.3679 147.774 85.2699 146.428 85.0739L119.619 81.1779C119.068 81.0979 118.556 80.9069 118.069 80.6689L118.12 80.7729Z"
            fill="#E26500"
        />
        <path
            d="M73.4475 142.413L78.0265 115.712C78.3215 113.989 77.7515 112.232 76.4995 111.012L74.2325 108.802C75.3025 110.003 75.8005 111.613 75.5265 113.212L70.9475 139.913C70.6615 141.579 71.9405 142.928 73.4315 143.006C73.4215 142.812 73.4125 142.618 73.4475 142.413Z"
            fill="#E26500"
        />
        <path
            d="M58.5719 87.5737L85.3809 83.6777C87.1109 83.4268 88.6059 82.3398 89.3799 80.7728L101.369 56.4797C101.927 55.3487 103.087 54.8888 104.161 55.0538L103.631 53.9797C102.657 52.0068 99.8429 52.0068 98.8689 53.9797L86.8799 78.2728C86.1059 79.8398 84.6109 80.9268 82.8809 81.1777L56.0719 85.0737C53.8939 85.3897 53.0249 88.0658 54.6009 89.6017L56.9069 91.8507C55.6429 90.2917 56.5039 87.8737 58.5719 87.5737Z"
            fill="#E26500"
        />
        <defs>
            <linearGradient
                id={'paint0_linear_2582_246' + props.id}
                x1="46.93"
                y1="15.961"
                x2="167.082"
                y2="174.803"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFEA00" />
                <stop offset="0.5" stop-color="#FFC700" />
                <stop offset="1" stop-color="#FFA500" />
            </linearGradient>
            <linearGradient
                id={'paint1_linear_2582_246' + props.id}
                x1="60.824"
                y1="38.561"
                x2="149.76"
                y2="154.957"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFE900" />
                <stop offset="0.5" stop-color="#FFC700" />
                <stop offset="1" stop-color="#FFA600" />
            </linearGradient>
            <linearGradient
                id={'paint2_linear_2582_246' + props.id}
                x1="101"
                y1="51.9998"
                x2="101"
                y2="142.523"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FFAD00" />
                <stop offset="0.5" stop-color="#FF8F00" />
                <stop offset="1" stop-color="#FF7100" />
            </linearGradient>
        </defs>
    </svg>
)

export default SvgComponent
