import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Popover from 'react-tiny-popover'

import RunOutOfGoldAssistantModal from '../../ChatsView/ChatDV/EditorView/BotOption/RunOutOfGoldAssistantModal'
import BotOptionsModal from '../../ChatsView/ChatDV/EditorView/BotOption/BotOptionsModal'
import { hideFloatPopup, setShowNotificationAboutTheBotBehavior, showFloatPopup } from '../../../redux/actions'
import { createBotQuickTopic, generateTaskFromPreConfig } from '../../../utils/assistantHelper'
import TalkToAssistant from './TalkToAssistant'

export default function MyDayBotOptionsModalWrapper({ projectId, disabled }) {
    const dispatch = useDispatch()
    const defaultAssistant = useSelector(state => state.defaultAssistant)
    const gold = useSelector(state => state.loggedUser.gold)
    const noticeAboutTheBotBehavior = useSelector(state => state.loggedUser.noticeAboutTheBotBehavior)
    const showNotificationAboutTheBotBehavior = useSelector(state => state.showNotificationAboutTheBotBehavior)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)

    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => {
        setIsOpen(false)
        dispatch(hideFloatPopup())
    }

    const openModal = () => {
        if (!noticeAboutTheBotBehavior) dispatch(setShowNotificationAboutTheBotBehavior(true))
        setIsOpen(true)
        dispatch(showFloatPopup())
        document.activeElement.blur()
    }

    const onSelectBotOption = (prompt, taskName) => {
        prompt
            ? generateTaskFromPreConfig(projectId, taskName, defaultAssistant.uid, prompt)
            : createBotQuickTopic(defaultAssistant)
    }

    return (
        <Popover
            content={
                gold > 0 ? (
                    <BotOptionsModal
                        closeModal={closeModal}
                        onSelectBotOption={onSelectBotOption}
                        assistantId={defaultAssistant.uid}
                        projectId={projectId}
                        objectType={'assistants'}
                        inMyDay={true}
                    />
                ) : (
                    <RunOutOfGoldAssistantModal closeModal={closeModal} />
                )
            }
            align={'start'}
            position={['top']}
            onClickOutside={closeModal}
            isOpen={isOpen && noticeAboutTheBotBehavior && !showNotificationAboutTheBotBehavior}
            contentLocation={smallScreenNavigation ? null : undefined}
        >
            <TalkToAssistant openModal={openModal} containerStyle={{ marginLeft: 8 }} disabled={disabled} />
        </Popover>
    )
}
