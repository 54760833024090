import * as React from 'react'

function PushNotificationsDesktop(props) {
    return (
        <svg width={256} height={256} viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                opacity={0.5}
                d="M15.892 6.448a6 6 0 00-6.306 5.678L8.226 38.09a6 6 0 005.677 6.306l41.289 2.164a2 2 0 011.365.641l4.92 5.333a2 2 0 002.79.146l5.452-4.79a2 2 0 011.425-.494l41.286 2.164a6 6 0 006.306-5.678l1.36-25.964a6 6 0 00-5.677-6.306L15.892 6.448z"
                fill="#05F"
            />
            <path
                opacity={0.5}
                d="M13.797 11.6a6 6 0 00-5.677 6.306l1.36 25.965a6 6 0 006.306 5.677l41.086-2.153a2 2 0 011.427.497l5.417 4.771a2 2 0 002.806-.16l4.772-5.28a2 2 0 011.38-.656l41.203-2.16a6 6 0 005.678-6.305l-1.36-25.964a6 6 0 00-6.306-5.678L13.798 11.6z"
                fill="#80DEEA"
            />
            <path
                d="M15.998 9a6 6 0 00-6 6v26a6 6 0 006 6H56.17a2 2 0 011.414.586l5 5a2 2 0 002.828 0l5-5A2 2 0 0171.826 47h40.172a6 6 0 006-6V15a6 6 0 00-6-6h-96z"
                fill="#152560"
            />
            <path
                d="M51.998 17a3 3 0 013-3h32a3 3 0 110 6h-32a3 3 0 01-3-3zM51.998 28a3 3 0 013-3h54a3 3 0 110 6h-54a3 3 0 01-3-3zM54.998 36a3 3 0 000 6h39a3 3 0 100-6h-39z"
                fill="#1A3289"
            />
            <rect x={11.998} y={11} width={34} height={34} rx={4} fill="#FEF1D7" />
            <path d="M35.208 37.01a2 2 0 11-1.828.813l-7.296-16.417 1.828-.812 7.296 16.417z" fill="#F58E0A" />
            <path
                d="M20.78 19.783c-3.14 3.108-3.108 8.173 0 11.314 5.638 5.638 4.253 7.06 4.243 7.07l-.354.354a1.5 1.5 0 102.121 2.121l14.85-14.849a1.5 1.5 0 00-2.121-2.121l-.354.354c-.032.03-1.478 1.351-7.071-4.243-3.108-3.14-8.174-3.108-11.314 0z"
                fill="#FDCB6E"
            />
            <path
                d="M18.658 20.49a2 2 0 112.828-2.828l.891.89a8.12 8.12 0 00-1.597 1.231c-.353 1.297-.353 4.596 2.476 7.424 4.093 4.094 4.86 5.598 5.403 6.663.077.152.15.295.228.435L39.52 23.672a1.5 1.5 0 112.121 2.121l-14.849 14.85a1.5 1.5 0 11-2.121-2.122l.011-.011-.012.01.354-.353c.01-.01 1.394-1.432-4.243-7.07-2.623-2.65-3.056-6.671-1.24-9.73l-.002.004-.88-.881z"
                fill="#FCB127"
            />
            <circle cx={42.998} cy={14} r={6} fill="#FF7043" />
            <path d="M44 11a1 1 0 10-2 0v3a1 1 0 102 0v-3zM43 18a1 1 0 100-2 1 1 0 000 2z" fill="#fff" />
            <path
                d="M68 98a2 2 0 00-2-2h-4a2 2 0 00-2 2v16a2 2 0 01-2 2h-5.5a1.5 1.5 0 000 3h23a1.5 1.5 0 000-3H70a2 2 0 01-2-2V98z"
                fill="#5AACFF"
            />
            <path
                d="M33 60a4 4 0 00-4 4v41a4 4 0 004 4h62a4 4 0 004-4V64a4 4 0 00-4-4H33zM52.5 116a1.5 1.5 0 000 3h23a1.5 1.5 0 000-3h-23z"
                fill="#A3D1FF"
            />
            <path d="M31 65a3 3 0 013-3h60a3 3 0 013 3v36H31V65z" fill="#fff" />
            <path d="M31 65a3 3 0 013-3h13v39H31V65z" fill="#0D55CF" />
            <path
                d="M51 67.5a1.5 1.5 0 011.5-1.5h39a1.5 1.5 0 010 3h-39a1.5 1.5 0 01-1.5-1.5zM51 74.5a1.5 1.5 0 011.5-1.5h39a1.5 1.5 0 010 3h-39a1.5 1.5 0 01-1.5-1.5zM52.5 80a1.5 1.5 0 000 3h39a1.5 1.5 0 000-3h-39zM51 88.5a1.5 1.5 0 011.5-1.5h39a1.5 1.5 0 010 3h-39a1.5 1.5 0 01-1.5-1.5zM52.5 94a1.5 1.5 0 000 3h39a1.5 1.5 0 000-3h-39z"
                fill="#E7ECEF"
            />
            <circle cx={34.5} cy={67.5} r={1.5} fill="#7F71EA" />
            <circle cx={34.5} cy={74.5} r={1.5} fill="#00CEC9" />
            <circle cx={34.5} cy={81.5} r={1.5} fill="#FDCB6E" />
            <path
                d="M38 67.5a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 01-1.5-1.5zM38 74.5a1.5 1.5 0 011.5-1.5h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 01-1.5-1.5zM39.5 80a1.5 1.5 0 000 3h4a1.5 1.5 0 000-3h-4z"
                fill="#0A44A5"
            />
            <path d="M33 53a11 11 0 00-11 11 1 1 0 11-2 0 13 13 0 0113-13 1 1 0 110 2z" fill="#09D693" />
            <path d="M33 56a8 8 0 00-8 8 1 1 0 11-2 0 10 10 0 0110-10 1 1 0 110 2z" fill="#09D693" />
            <path
                d="M29.465 60.465A5 5 0 0133 59a1 1 0 100-2 7 7 0 00-7 7 1 1 0 102 0 5 5 0 011.465-3.535zM98.535 60.465A5 5 0 0095 59a1 1 0 110-2 7 7 0 017 7 1 1 0 01-2 0 5 5 0 00-1.465-3.535z"
                fill="#09D693"
            />
            <path
                d="M100.657 58.343A8 8 0 0095 56a1 1 0 110-2 10 10 0 0110 10 1 1 0 01-2 0 8.001 8.001 0 00-2.343-5.657z"
                fill="#09D693"
            />
            <path
                d="M102.778 56.222A11 11 0 0095 53a1 1 0 110-2 12.999 12.999 0 0113 13 1 1 0 01-2 0 11 11 0 00-3.222-7.778z"
                fill="#09D693"
            />
            <circle cx={64} cy={105} r={2} fill="#EBF5FF" />
            <path d="M66 92a2 2 0 012-2h23a2 2 0 012 2v6a2 2 0 01-2 2H68a2 2 0 01-2-2v-6z" fill="#152560" />
            <path
                d="M76.25 92a.75.75 0 01.75-.75h10a.75.75 0 010 1.5H77a.75.75 0 01-.75-.75zM76.25 95a.75.75 0 01.75-.75h13.5a.75.75 0 010 1.5H77a.75.75 0 01-.75-.75zM77 97.25a.75.75 0 000 1.5h10a.75.75 0 000-1.5H77z"
                fill="#1A3289"
            />
            <rect x={67} y={91} width={8} height={8} rx={1} fill="#FEF1D7" />
            <path d="M72.461 97.12a.47.47 0 11-.43.191l-1.717-3.863.43-.19 1.717 3.862z" fill="#F58E0A" />
            <path
                d="M69.067 93.067a1.882 1.882 0 000 2.662c1.326 1.326 1 1.661.998 1.663l-.083.084a.353.353 0 10.499.499l3.494-3.494a.353.353 0 10-.5-.5l-.082.084c-.008.007-.348.318-1.664-.998a1.882 1.882 0 00-2.662 0z"
                fill="#FDCB6E"
            />
            <path
                d="M68.567 93.233a.47.47 0 11.666-.666l.21.21a1.91 1.91 0 00-.376.29c-.083.305-.083 1.081.582 1.747.963.963 1.144 1.317 1.272 1.567l.053.103 2.502-2.502a.353.353 0 01.5.499l-3.495 3.494a.353.353 0 01-.499-.5l.003-.002-.003.002.083-.083c.002-.002.329-.337-.998-1.663a1.89 1.89 0 01-.292-2.29v.001l-.208-.207z"
                fill="#FCB127"
            />
            <circle cx={75} cy={91} r={2} fill="#FF7043" />
            <path
                d="M75 89.589a.32.32 0 00-.32.32v1.122a.32.32 0 00.64 0V89.91a.32.32 0 00-.32-.32zM75 91.686a.32.32 0 100 .64.32.32 0 000-.64z"
                fill="#fff"
            />
        </svg>
    )
}

export default PushNotificationsDesktop
