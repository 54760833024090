import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    hideWebSideBar,
    setSelectedSidebarTab,
    setSelectedTypeOfProject,
    setTaskViewToggleIndex,
    setTaskViewToggleSection,
    storeCurrentShortcutUser,
    storeCurrentUser,
    switchProject,
    switchShortcutProject,
} from '../../../redux/actions'
import { DV_TAB_ROOT_TASKS } from '../../../utils/TabNavigationConstants'
import { translate } from '../../../i18n/TranslationService'
import Button from '../../UIControls/Button'
import { colors } from '../../styles/global'
import { setAssistantLastVisitedBoardDate } from '../../../utils/backends/Assistants/assistantsFirestore'
import { GLOBAL_PROJECT_ID } from '../../AdminPanel/Assistants/assistantsHelper'
import ProjectHelper from '../../SettingsView/ProjectsSettings/ProjectHelper'
import { PROJECT_TYPE_ACTIVE } from '../../SettingsView/ProjectsSettings/ProjectsSettings'

export default function GoToDvButton() {
    const dispatch = useDispatch()
    const defaultProjectId = useSelector(state => state.loggedUser.defaultProjectId)
    const defaultAssistant = useSelector(state => state.defaultAssistant)
    const showFloatPopup = useSelector(state => state.showFloatPopup)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)

    const navigateToAssistantBoard = () => {
        if (showFloatPopup === 0) {
            const project = ProjectHelper.getProjectById(defaultProjectId)

            setAssistantLastVisitedBoardDate(GLOBAL_PROJECT_ID, defaultAssistant, defaultProjectId, 'lastVisitBoard')

            let dispatches = [
                setSelectedSidebarTab(DV_TAB_ROOT_TASKS),
                storeCurrentUser(defaultAssistant),
                setSelectedTypeOfProject(PROJECT_TYPE_ACTIVE),
                storeCurrentShortcutUser(null),
                setTaskViewToggleIndex(0),
                setTaskViewToggleSection('Open'),
                switchProject(project.index),
                switchShortcutProject(project.index),
            ]

            if (smallScreenNavigation) dispatches.push(hideWebSideBar())

            dispatch(dispatches)
        }
    }

    return (
        <Button
            ref={ref => (this.openBtnRef = ref)}
            title={translate('Open')}
            titleStyle={{ color: colors.Text03, fontSize: 12 }}
            buttonStyle={{ backgroundColor: '#f1f5f7', marginTop: 2 }}
            type={'ghost'}
            noBorder={true}
            icon={'maximize-2'}
            iconSize={18}
            onPress={navigateToAssistantBoard}
            disabled={!defaultProjectId}
        />
    )
}
