import * as React from 'react'
import Svg, { Path, Defs, LinearGradient, Stop, RadialGradient } from 'react-native-svg'

const GoogleCalendar = props => (
    <Svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M16.506 1.652H3.494c-.744 0-1.346.586-1.346 1.31l.026 8.288h15.679V2.961c0-.723-.603-1.309-1.347-1.309Z"
            fill="#C2C2C2"
        />
        <Path
            d="M17.404 4.27H2.596c-.743 0-1.346.585-1.346 1.308 0 .061.005.121.013.18l.885 5.492h15.704l.886-5.493c.008-.059.012-.119.012-.179 0-.723-.602-1.308-1.346-1.308Z"
            fill="#3367D6"
        />
        <Path
            opacity={0.5}
            d="M17.404 4.27H2.596c-.743 0-1.346.585-1.346 1.308 0 .061.005.121.013.18l.885 5.492h15.704l.886-5.493c.008-.059.012-.119.012-.179 0-.723-.602-1.308-1.346-1.308Z"
            fill="#2A56C6"
        />
        <Path
            d="m18.298 16.831-.445-5.581H2.148l-.445 5.581c-.003.03-.004.06-.004.09 0 .724.603 1.31 1.346 1.31h13.91c.744 0 1.346-.586 1.346-1.31 0-.03 0-.06-.003-.09Z"
            fill="#4285F4"
        />
        <Path d="m2.148 11.25 7.18 6.98H18.3v-6.98H2.148Z" fill="url(#a)" />
        <Path
            d="M2.596 4.379h14.808c.728 0 1.32.562 1.344 1.265l.002-.066c0-.723-.602-1.308-1.346-1.308H2.596c-.743 0-1.346.585-1.346 1.308l.003.066c.023-.703.615-1.265 1.343-1.265Z"
            fill="#fff"
            fillOpacity={0.2}
        />
        <Path
            d="M16.955 18.12H3.045c-.728 0-1.32-.562-1.344-1.265a1.264 1.264 0 0 0-.002.066c0 .723.603 1.309 1.346 1.309h13.91c.744 0 1.346-.586 1.346-1.31 0-.02 0-.043-.002-.065-.023.703-.616 1.266-1.344 1.266Z"
            fill="#1A237E"
            fillOpacity={0.2}
        />
        <Path
            d="M16.506 1.652H3.494c-.744 0-1.346.586-1.346 1.31v.103c.003-.72.604-1.304 1.346-1.304h13.012c.744 0 1.347.586 1.347 1.31v-.11c0-.723-.603-1.309-1.347-1.309Z"
            fill="#fff"
            fillOpacity={0.2}
        />
        <Path
            d="M8.683 11.158c.51-.205 1.168-.75 1.168-1.572 0-1.265-.836-2.043-2.243-2.043-1.255 0-2.265.798-2.283 1.963h.91c.014-.68.487-1.24 1.371-1.24.852 0 1.332.43 1.332 1.3 0 .87-.684 1.27-1.519 1.27h-.636v.695h.636c1.227 0 1.631.62 1.631 1.338 0 .94-.588 1.374-1.44 1.374-.74 0-1.416-.385-1.436-1.245h-.918c.04 1.386 1.24 1.963 2.355 1.963 1.288 0 2.35-.73 2.35-2.112.002-1.114-.814-1.56-1.278-1.691Zm5.208-3.615-2.657.927v.833l1.907-.62v6.277h.898V7.543h-.148Z"
            fill="#E1E1E1"
        />
        <Path
            opacity={0.5}
            d="M8.683 11.158c.51-.205 1.168-.75 1.168-1.572 0-1.265-.836-2.043-2.243-2.043-1.255 0-2.265.798-2.283 1.963h.91c.014-.68.487-1.24 1.371-1.24.852 0 1.332.43 1.332 1.3 0 .87-.684 1.27-1.519 1.27h-.636v.695h.636c1.227 0 1.631.62 1.631 1.338 0 .94-.588 1.374-1.44 1.374-.74 0-1.416-.385-1.436-1.245h-.918c.04 1.386 1.24 1.963 2.355 1.963 1.288 0 2.35-.73 2.35-2.112.002-1.114-.814-1.56-1.278-1.691Zm5.208-3.615-2.657.927v.833l1.907-.62v6.277h.898V7.543h-.148Z"
            fill="#C2C2C2"
        />
        <Path
            d="M8.683 11.158c.51-.205 1.168-.75 1.168-1.572 0-1.265-.836-2.043-2.243-2.043-1.255 0-2.265.798-2.283 1.963h.91c.014-.68.487-1.24 1.371-1.24.852 0 1.332.43 1.332 1.3 0 .87-.684 1.27-1.519 1.27h-.636v.695h.636c1.227 0 1.631.62 1.631 1.338 0 .94-.588 1.374-1.44 1.374-.74 0-1.416-.385-1.436-1.245h-.918c.04 1.386 1.24 1.963 2.355 1.963 1.288 0 2.35-.73 2.35-2.112.002-1.114-.814-1.56-1.278-1.691Zm5.208-3.615-2.657.927v.833l1.907-.62v6.277h.898V7.543h-.148Z"
            fill="#EEE"
        />
        <Path d="m6.131 4.271-.869-.846.951-.925 1.823 1.771H6.131Z" fill="url(#b)" />
        <Path
            d="M5.738 3.615a.664.664 0 0 0 .673-.654.664.664 0 0 0-.673-.654.664.664 0 0 0-.674.654c0 .361.302.655.674.655Z"
            fill="#EEE"
        />
        <Path
            d="M5.737 2.418c.354 0 .642.264.67.6a.664.664 0 0 0-.67-.709.664.664 0 0 0-.67.709.664.664 0 0 1 .67-.6Z"
            fill="#fff"
            fillOpacity={0.4}
        />
        <Path
            d="M6.407 3.016a.665.665 0 0 1-.67.6.665.665 0 0 1-.67-.6.664.664 0 0 0 .67.709.664.664 0 0 0 .67-.71Z"
            fill="#212121"
            fillOpacity={0.1}
        />
        <Path d="M17.965 11.25H2.035v.11h15.93v-.11Z" fill="#fff" fillOpacity={0.05} />
        <Path d="M17.965 11.14H2.036v.11h15.93v-.11Z" fill="#1A237E" fillOpacity={0.05} />
        <Path d="m14.657 4.271-.87-.846.951-.925 1.375 1.771h-1.456Z" fill="url(#c)" />
        <Path
            d="M14.264 3.615a.664.664 0 0 0 .673-.654.664.664 0 0 0-.673-.654.664.664 0 0 0-.673.654c0 .361.301.655.673.655Z"
            fill="#EEE"
        />
        <Path
            d="M14.264 2.418c.354 0 .642.264.67.6.001-.019.003-.036.003-.055a.665.665 0 0 0-.673-.654.664.664 0 0 0-.67.709.664.664 0 0 1 .67-.6Z"
            fill="#fff"
            fillOpacity={0.4}
        />
        <Path
            d="M14.933 3.016a.665.665 0 0 1-.67.6.665.665 0 0 1-.67-.6l-.003.054c0 .361.302.655.673.655a.665.665 0 0 0 .67-.71Z"
            fill="#212121"
            fillOpacity={0.1}
        />
        <Path
            d="M18.75 5.579c0-.57-.375-1.053-.897-1.233V2.961c0-.723-.603-1.309-1.347-1.309H3.494c-.744 0-1.346.586-1.346 1.31l.003 1.382a1.312 1.312 0 0 0-.888 1.414l.885 5.493-.446 5.58c-.002.031-.003.06-.003.091 0 .723.602 1.309 1.346 1.309h13.91c.744 0 1.346-.586 1.346-1.309 0-.03 0-.06-.003-.09l-.445-5.582.885-5.492c.008-.058.012-.118.012-.18Z"
            fill="url(#d)"
            fillOpacity={0.05}
        />
        <Defs>
            <LinearGradient id="a" x1={6.221} y1={7.36} x2={13.37} y2={14.712} gradientUnits="userSpaceOnUse">
                <Stop stopColor="#1A237E" stopOpacity={0.2} />
                <Stop offset={1} stopColor="#1A237E" stopOpacity={0.02} />
            </LinearGradient>
            <LinearGradient id="b" x1={5.726} y1={2.954} x2={6.575} y2={3.826} gradientUnits="userSpaceOnUse">
                <Stop stopColor="#212121" stopOpacity={0.2} />
                <Stop offset={1} stopColor="#212121" stopOpacity={0.02} />
            </LinearGradient>
            <LinearGradient id="c" x1={14.252} y1={2.955} x2={14.995} y2={3.72} gradientUnits="userSpaceOnUse">
                <Stop stopColor="#212121" stopOpacity={0.2} />
                <Stop offset={1} stopColor="#212121" stopOpacity={0.02} />
            </LinearGradient>
            <RadialGradient
                id="d"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(22.4627 0 0 21.8405 2.452 2.019)"
            >
                <Stop offset={0.33} stopColor="#fff" />
                <Stop offset={1} stopColor="#fff" stopOpacity={0} />
            </RadialGradient>
        </Defs>
    </Svg>
)

export default GoogleCalendar
