import Colors from '../Colors'

export const PROJECT_COLOR_DEFAULT = Colors.Marker_PrimaryBlue_01
export const PROJECT_COLOR_BLUE = Colors.Marker_Blue_01
export const PROJECT_COLOR_RED = Colors.Marker_Red_01
export const PROJECT_COLOR_PURPLE = Colors.Marker_Purple_01
export const PROJECT_COLOR_GREEN = Colors.Marker_Green_01
export const PROJECT_COLOR_PINK = Colors.Marker_Pink_01
export const PROJECT_COLOR_ORANGE = Colors.Marker_Orange_01
export const PROJECT_COLOR_YELLOW = Colors.Marker_Yellow_01
export const PROJECT_COLOR_PELOROUS = Colors.Marker_Pelorous_01
export const PROJECT_COLOR_LIME = Colors.Marker_Lime_01
export const PROJECT_COLOR_VIOLET = Colors.Marker_Violet_01

export const PROJECT_COLOR_SYSTEM = {
    [PROJECT_COLOR_DEFAULT]: {
        MARKER: Colors.Marker_PrimaryBlue_01,
        MARKER_TEXT: Colors.Marker_PrimaryBlue_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_PrimaryBlue_03,
        PROJECT_ITEM_SECTION: Colors.Marker_PrimaryBlue_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_PrimaryBlue_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_PrimaryBlue_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_PrimaryBlue_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_PrimaryBlue_02,
    },
    [PROJECT_COLOR_BLUE]: {
        MARKER: Colors.Marker_Blue_01,
        MARKER_TEXT: Colors.Marker_Blue_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Blue_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Blue_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Blue_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Blue_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Blue_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Blue_02,
    },
    [PROJECT_COLOR_RED]: {
        MARKER: Colors.Marker_Red_01,
        MARKER_TEXT: Colors.Marker_Red_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Red_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Red_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Red_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Red_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Red_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Red_02,
    },
    [PROJECT_COLOR_PURPLE]: {
        MARKER: Colors.Marker_Purple_01,
        MARKER_TEXT: Colors.Marker_Purple_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Purple_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Purple_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Purple_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Purple_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Purple_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Purple_02,
    },
    [PROJECT_COLOR_GREEN]: {
        MARKER: Colors.Marker_Green_01,
        MARKER_TEXT: Colors.Marker_Green_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Green_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Green_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Green_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Green_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Green_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Green_02,
    },
    [PROJECT_COLOR_PINK]: {
        MARKER: Colors.Marker_Pink_01,
        MARKER_TEXT: Colors.Marker_Pink_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Pink_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Pink_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Pink_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Pink_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Pink_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Pink_02,
    },
    [PROJECT_COLOR_ORANGE]: {
        MARKER: Colors.Marker_Orange_01,
        MARKER_TEXT: Colors.Marker_Orange_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Orange_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Orange_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Orange_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Orange_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Orange_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Orange_02,
    },
    [PROJECT_COLOR_YELLOW]: {
        MARKER: Colors.Marker_Yellow_01,
        MARKER_TEXT: Colors.Marker_Yellow_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Yellow_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Yellow_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Yellow_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Yellow_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Yellow_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Yellow_02,
    },
    [PROJECT_COLOR_PELOROUS]: {
        MARKER: Colors.Marker_Pelorous_01,
        MARKER_TEXT: Colors.Marker_Pelorous_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Pelorous_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Pelorous_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Pelorous_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Pelorous_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Pelorous_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Pelorous_02,
    },
    [PROJECT_COLOR_LIME]: {
        MARKER: Colors.Marker_Lime_01,
        MARKER_TEXT: Colors.Marker_Lime_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Lime_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Lime_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Lime_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Lime_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Lime_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Lime_02,
    },
    [PROJECT_COLOR_VIOLET]: {
        MARKER: Colors.Marker_Violet_01,
        MARKER_TEXT: Colors.Marker_Violet_Dark,
        PROJECT_ITEM: Colors.GraySidebar,
        PROJECT_ITEM_ACTIVE: Colors.Marker_Violet_03,
        PROJECT_ITEM_SECTION: Colors.Marker_Violet_05,
        PROJECT_ITEM_SECTION_ACTIVE: Colors.Marker_Violet_02,
        PROJECT_ITEM_SECTION_SELECTED: Colors.Marker_Violet_025,
        PROJECT_ITEM_SECTION_ITEM: Colors.Marker_Violet_04,
        PROJECT_ITEM_SECTION_ITEM_ACTIVE: Colors.Marker_Violet_02,
    },
}
