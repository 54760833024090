import store from '../../redux/store'
import { setEndDataForNewUser, setInitialDataForNewUser } from '../../redux/actions'
import {
    mapUserData,
    addToMarketingList,
    getId,
    createUploadNewUserFeeds,
    logEvent,
    initGoogleTagManager,
    watchForceReload,
    initFCMonLoad,
    unwatch,
} from '../backends/firestore'
import { addNewUserToAlldoneTemplate, uploadNewUser } from '../backends/Users/usersFirestore'
import URLTrigger from '../../URLSystem/URLTrigger'
import { getNewDefaultUser } from '../../components/ContactsView/Utils/ContactsHelper'
import ProjectHelper from '../../components/SettingsView/ProjectsSettings/ProjectHelper'
import TasksHelper from '../../components/TaskListView/Utils/TasksHelper'
import { getDefaultMainWorkstream } from '../../components/Workstreams/WorkstreamHelper'
import {
    convertAnonymousProjectsIntoSharedProjects,
    handleCookies,
    loadGlobalData,
    watchLoggedUserData,
    watchProjectData,
    watchProjectsChatNotifications,
} from './initialLoadHelper'
import { getDateFormatFromCurrentLocation } from '../Geolocation/GeolocationHelper'
import { storeVersion } from '../Observers'
import NavigationService from '../NavigationService'

const generateInitialWorkstream = (projectId, userId) => {
    return getDefaultMainWorkstream(projectId, userId)
}

const generateUser = async (firebaseUser, projectId) => {
    const { initialUrl } = store.getState()
    const { uid: userId, email, displayName, photoURL } = firebaseUser

    const user = getNewDefaultUser({
        uid: userId,
        displayName,
        email,
        photoURL,
        singUpUrl: initialUrl,
        defaultProjectId: projectId,
        projectIds: [projectId],
        lastEditionDate: Date.now(),
        lastEditorId: userId,
    })

    const { dateFormat, mondayFirstInCalendar } = await getDateFormatFromCurrentLocation()
    user.dateFormat = dateFormat
    user.mondayFirstInCalendar = mondayFirstInCalendar

    return user
}

const generateInitialProject = userId => {
    const project = ProjectHelper.getNewDefaultProject()

    project.id = getId()
    project.index = 0
    project.creatorId = userId
    project.name = 'Private life'
    project.userIds = [userId]

    return project
}

const generateInitialTask = userId => {
    const task = TasksHelper.getNewDefaultTask()

    task.id = getId()
    task.name = 'Add your private tasks here :)'
    task.extendedName = task.name
    task.userId = userId
    task.creatorId = userId
    task.userIds = [userId]
    task.currentReviewerId = userId
    task.lastEditorId = userId

    return task
}

export const processNewUser = async firebaseUser => {
    unwatch('loggedUser')

    initGoogleTagManager(userId)
    watchForceReload(userId, false)
    storeVersion()

    await loadGlobalData()

    const { initialUrl } = store.getState()
    const { uid: userId, email } = firebaseUser

    const project = generateInitialProject(userId)
    const workstream = generateInitialWorkstream(project.id, userId)
    const task = generateInitialTask(userId)
    const user = await generateUser(firebaseUser, project.id)

    const mappedUser = mapUserData(userId, user, true)

    const projects = [project]
    const projectsMap = { [project.id]: project }
    const projectUsers = { [project.id]: [mappedUser] }
    const projectContacts = { [project.id]: [] }
    const projectWorkstreams = { [project.id]: [workstream] }
    const projectAssistants = { [project.id]: [] }

    convertAnonymousProjectsIntoSharedProjects(
        projects,
        projectsMap,
        projectUsers,
        projectContacts,
        projectWorkstreams,
        projectAssistants
    )

    store.dispatch(
        setInitialDataForNewUser(
            user,
            projects,
            projectsMap,
            projectUsers,
            projectContacts,
            projectWorkstreams,
            projectAssistants
        )
    )

    createUploadNewUserFeeds(mappedUser, userId, project.id, project, task.id, task)
    await uploadNewUser(userId, user, project, task, workstream)

    watchLoggedUserData(user)
    watchProjectData(project.id, true, false)
    watchProjectsChatNotifications()

    await addNewUserToAlldoneTemplate(userId)
    store.dispatch(setEndDataForNewUser())

    addToMarketingList(email, initialUrl)
    logEvent('new_user', { id: userId, email })

    initFCMonLoad()

    //handleCookies()

    const url = initialUrl !== '/' ? initialUrl : window.location.pathname
    URLTrigger.processUrl(NavigationService, url)
}
