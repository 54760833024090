import React, { useState } from 'react'
import Popover from 'react-tiny-popover'
import { useDispatch, useSelector } from 'react-redux'
import Hotkeys from 'react-hot-keys'

import Button from '../../../UIControls/Button'
import { hideFloatPopup, showFloatPopup } from '../../../../redux/actions'
import { execShortcutFn } from '../../../UIComponents/ShortcutCheatSheet/HelperFunctions'
import PreConfigTaskModal from '../../../UIComponents/FloatModals/PreConfigTaskModal/PreConfigTaskModal'

export default function AddPreConfigTaskWrapper({ disabled, projectId, assistantId, task, adding }) {
    const dispatch = useDispatch()
    const blockShortcuts = useSelector(state => state.blockShortcuts)
    const mobile = useSelector(state => state.smallScreenNavigation)
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
        dispatch(showFloatPopup())
    }

    const closeModal = () => {
        setIsOpen(false)
        dispatch(hideFloatPopup())
    }

    return (
        <Popover
            content={
                <PreConfigTaskModal
                    projectId={projectId}
                    closeModal={closeModal}
                    adding={adding}
                    assistantId={assistantId}
                    task={task}
                    disabled={disabled}
                />
            }
            align={'start'}
            position={['bottom']}
            onClickOutside={closeModal}
            isOpen={isOpen}
            contentLocation={mobile ? null : undefined}
        >
            {adding ? (
                <Hotkeys
                    keyName={'alt+C'}
                    disabled={blockShortcuts}
                    onKeyDown={(sht, event) => execShortcutFn(this.btnRef, openModal, event)}
                    filter={e => true}
                >
                    <Button
                        ref={ref => (this.btnRef = ref)}
                        type={'ghost'}
                        icon={'edit'}
                        onPress={openModal}
                        disabled={isOpen}
                        shortcutText={'C'}
                    />
                </Hotkeys>
            ) : (
                <Button
                    ref={ref => (this.btnRef = ref)}
                    type={'ghost'}
                    icon={'edit'}
                    onPress={openModal}
                    disabled={isOpen}
                />
            )}
        </Popover>
    )
}
