import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import v4 from 'uuid/v4'

import { watchAssistantTasks } from '../../../../utils/backends/Assistants/assistantsFirestore'
import { unwatch } from '../../../../utils/backends/firestore'
import PreConfigTaskItem from './PreConfigTaskItem'

export default function PreConfigTaskList({ disabled, projectId, assistantId }) {
    const smallScreen = useSelector(state => state.smallScreen)
    const [tasks, setTasks] = useState([])

    useEffect(() => {
        const watcherKey = v4()
        watchAssistantTasks(projectId, assistantId, watcherKey, setTasks)
        return () => {
            unwatch(watcherKey)
        }
    }, [])

    return (
        <View style={{ marginRight: smallScreen ? 0 : 72, width: '100%' }}>
            {tasks.map(task => (
                <PreConfigTaskItem
                    disabled={disabled}
                    key={task.id}
                    projectId={projectId}
                    task={task}
                    assistantId={assistantId}
                />
            ))}
        </View>
    )
}
