import React from 'react'
import { View } from 'react-native'

import Tags from './Tags'

export default function TagsInCalendarSpace({
    task,
    isSubtask,
    projectId,
    isObservedTask,
    isToReviewTask,
    toggleSubTaskList,
    subtaskList,
    isActiveOrganizeMode,
    accessGranted,
    anonymousGranted,
    forceTagsMobile,
    isLocked,
    isSuggested,
    isPending,
}) {
    return (
        <View
            style={{
                flex: 1,
                marginHorizontal: 8,
                paddingHorizontal: 8,
                position: 'absolute',
                right: 0,
                top: 39.5,
            }}
        >
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
                <Tags
                    task={task}
                    isSubtask={isSubtask}
                    projectId={projectId}
                    isObservedTask={isObservedTask}
                    isToReviewTask={isToReviewTask}
                    toggleSubTaskList={toggleSubTaskList}
                    subtaskList={subtaskList}
                    isActiveOrganizeMode={isActiveOrganizeMode}
                    accessGranted={accessGranted}
                    anonymousGranted={anonymousGranted}
                    forceTagsMobile={forceTagsMobile}
                    isLocked={isLocked}
                    isSuggested={isSuggested}
                    tagsStyle={{ marginBottom: 8 }}
                    isPending={isPending}
                    inCalendarSpace={true}
                />
            </View>
        </View>
    )
}
