import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TouchableOpacity } from 'react-native'

import AssistantAvatar from '../../AdminPanel/Assistants/AssistantAvatar'
import ProjectHelper from '../../SettingsView/ProjectsSettings/ProjectHelper'
import { setAssistantLastVisitedBoardDate } from '../../../utils/backends/Assistants/assistantsFirestore'
import { GLOBAL_PROJECT_ID } from '../../AdminPanel/Assistants/assistantsHelper'
import {
    hideWebSideBar,
    setSelectedSidebarTab,
    setSelectedTypeOfProject,
    setTaskViewToggleIndex,
    setTaskViewToggleSection,
    storeCurrentShortcutUser,
    storeCurrentUser,
    switchProject,
    switchShortcutProject,
} from '../../../redux/actions'
import { DV_TAB_ROOT_TASKS } from '../../../utils/TabNavigationConstants'
import { PROJECT_TYPE_ACTIVE } from '../../SettingsView/ProjectsSettings/ProjectsSettings'

export default function DefaultAssistantAvatar() {
    const dispatch = useDispatch()
    const defaultProjectId = useSelector(state => state.loggedUser.defaultProjectId)
    const defaultAssistant = useSelector(state => state.defaultAssistant)
    const showFloatPopup = useSelector(state => state.showFloatPopup)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)

    const navigateToAssistantBoard = () => {
        if (showFloatPopup === 0) {
            const project = ProjectHelper.getProjectById(defaultProjectId)

            setAssistantLastVisitedBoardDate(GLOBAL_PROJECT_ID, defaultAssistant, defaultProjectId, 'lastVisitBoard')

            let dispatches = [
                setSelectedSidebarTab(DV_TAB_ROOT_TASKS),
                storeCurrentUser(defaultAssistant),
                setSelectedTypeOfProject(PROJECT_TYPE_ACTIVE),
                storeCurrentShortcutUser(null),
                setTaskViewToggleIndex(0),
                setTaskViewToggleSection('Open'),
                switchProject(project.index),
                switchShortcutProject(project.index),
            ]

            if (smallScreenNavigation) dispatches.push(hideWebSideBar())

            dispatch(dispatches)
        }
    }

    return (
        <TouchableOpacity onPress={navigateToAssistantBoard} disabled={!defaultProjectId}>
            <AssistantAvatar
                photoURL={defaultAssistant.photoURL300}
                assistantId={defaultAssistant.uid}
                size={100}
                imageStyle={{ borderRadius: 8 }}
            />
        </TouchableOpacity>
    )
}
