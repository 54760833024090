import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles from '../../../styles/global'
import { translate } from '../../../../i18n/TranslationService'
import { shrinkTagText } from '../../../../functions/Utils/parseTextUtils'

export default function Header({ title }) {
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)

    const text = title
        ? shrinkTagText(title, smallScreenNavigation ? 20 : isMiddleScreen ? 30 : 40)
        : translate('Comment')

    return (
        <View style={localStyles.headingContainer}>
            <Text style={[styles.title7, { color: '#ffffff' }]}>{text}</Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    headingContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
})
