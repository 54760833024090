import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'

import MyDayBotOptionsModalWrapper from './MyDayBotOptionsModalWrapper'
import AddTaskTag from '../../Tags/AddTaskTag'
import { FEED_TASK_OBJECT_TYPE } from '../../Feeds/Utils/FeedsConstants'

export default function MyDayBotLineButtons() {
    const defaultProjectId = useSelector(state => state.loggedUser.defaultProjectId)
    const currentSection = useSelector(state => state.taskViewToggleSection)

    const inOpenSection = currentSection === 'Open'
    const disablButtons = !defaultProjectId

    return (
        <View style={localStyles.container}>
            <MyDayBotOptionsModalWrapper projectId={defaultProjectId} disabled={disablButtons} />
            {inOpenSection && (
                <AddTaskTag
                    projectId={defaultProjectId}
                    style={{ marginLeft: 8 }}
                    sourceType={FEED_TASK_OBJECT_TYPE}
                    disabled={disablButtons}
                    showProjectSelector={true}
                    expandTaskListIfNeeded={true}
                />
            )}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginTop: 10,
        position: 'absolute',
        right: 16,
        bottom: 14,
    },
})
