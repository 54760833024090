import React from 'react'
import { useSelector } from 'react-redux'

import { colors } from '../../../styles/global'
import ChatIndicator from '../../../ChatsView/ChatIndicator'
import { getNewFollowedAndUnfollowedCommentsAmountInProjectList } from '../../../../utils/backends/Chats/chatsComments'

export default function ChatsAmountTemplatesProjects() {
    const realTemplateProjectIds = useSelector(state => state.loggedUser.realTemplateProjectIds)
    const projectChatNotifications = useSelector(state => state.projectChatNotifications)

    const { totalFollowed, totalUnfollowed } = getNewFollowedAndUnfollowedCommentsAmountInProjectList(
        projectChatNotifications,
        realTemplateProjectIds
    )

    const notificationsAmount = totalFollowed || totalUnfollowed
    const backgroundColor = totalFollowed ? colors.UtilityRed200 : colors.Gray500

    return (
        !!notificationsAmount && (
            <ChatIndicator notificationsAmount={notificationsAmount} backgroundColor={backgroundColor} />
        )
    )
}
