import React from 'react'
import { StyleSheet, View } from 'react-native'

import DefaultAssistantName from './DefaultAssistantName'
import DefaultAssistantDescription from './DefaultAssistantDescription'
import GoToDvButton from './GoToDvButton'

export default function DefaultAssistantData() {
    return (
        <View style={localStyles.container}>
            <View>
                <DefaultAssistantName />
                <DefaultAssistantDescription />
            </View>
            <View>
                <GoToDvButton />
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        marginLeft: 16,
        width: 260,
        justifyContent: 'space-between',
    },
})
