import React, { useRef } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

import global, { colors } from '../../../styles/global'
import CommentElementsParser from '../../../Feeds/TextParser/CommentElementsParser'
import { divideQuotedText } from './quoteParserFunctions'
import QuotedText from './QuotedText'
import ChatInput from './ChatInput'
import DismissibleItem from '../../../UIComponents/DismissibleItem'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveChatMessageId } from '../../../../redux/actions'
import { divideCodeText } from './codeParserFunctions'
import CodeText from './CodeText'

export default function MessageItemContent({
    messageId,
    creatorId,
    projectId,
    commentText,
    chatTitle,
    members,
    chat,
    blockOpen,
    dismissibleRef,
    creatorData,
    objectType,
    setAmountOfNewCommentsToHighligth,
}) {
    const dispatch = useDispatch()
    const showFloatPopup = useSelector(state => state.showFloatPopup)
    const activeChatMessageId = useSelector(state => state.activeChatMessageId)
    const userIsAnonymous = useSelector(state => state.loggedUser.isAnonymous)

    const textsFiltered = divideQuotedText(commentText, 'quote')

    const enableEditMode = () => {
        if (!blockOpen && activeChatMessageId === '' && !showFloatPopup) {
            dispatch(setActiveChatMessageId(messageId))
            dismissibleRef.current.openModal()
        }
    }

    const closeEditMode = () => {
        dismissibleRef.current.closeModal()
    }

    useEffect(() => {
        if (dismissibleRef.current.modalIsVisible() && activeChatMessageId !== messageId) {
            closeEditMode()
        }
    }, [activeChatMessageId])

    return (
        <DismissibleItem
            ref={dismissibleRef}
            defaultComponent={
                <TouchableOpacity style={{ marginLeft: 36 }} onPress={enableEditMode} disabled={userIsAnonymous}>
                    {textsFiltered.map((commentData, index) => {
                        const { type, text } = commentData

                        const lastItem = index === textsFiltered.length - 1
                        if (type === 'quote') {
                            return (
                                <QuotedText key={index} projectId={projectId} lastItem={lastItem} quotedText={text} />
                            )
                        } else {
                            const textData = divideCodeText(text)

                            return textData.map((data, index) => {
                                const lastItemInsideItem = lastItem && index === textData.length - 1
                                if (data.type === 'code') {
                                    return <CodeText key={index} lastItem={lastItemInsideItem} text={data.text} />
                                } else {
                                    return (
                                        <CommentElementsParser
                                            key={index}
                                            comment={data.text}
                                            containerStyle={!lastItemInsideItem && { marginBottom: 16 }}
                                            entryStyle={localStyles.text}
                                            projectId={projectId}
                                            inChat={true}
                                        />
                                    )
                                }
                            })
                        }
                    })}
                </TouchableOpacity>
            }
            modalComponent={
                <ChatInput
                    chat={chat}
                    projectId={projectId}
                    chatTitle={chatTitle}
                    members={members}
                    containerStyle={{
                        bottom: undefined,
                        left: undefined,
                        right: undefined,
                        marginRight: 16,
                        marginTop: 8,
                    }}
                    initialText={commentText}
                    editing={true}
                    messageId={messageId}
                    closeEditMode={closeEditMode}
                    creatorId={creatorId}
                    creatorData={creatorData}
                    objectType={objectType}
                    setAmountOfNewCommentsToHighligth={setAmountOfNewCommentsToHighligth}
                />
            }
            onToggleModal={visable => {
                if (!visable) dispatch(setActiveChatMessageId(''))
            }}
        />
    )
}

const localStyles = StyleSheet.create({
    text: {
        ...global.body1,
        color: colors.Text02,
    },
})
