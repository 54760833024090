import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import Icon from '../Icon'
import ProjectHelper from '../SettingsView/ProjectsSettings/ProjectHelper'
import Shortcut from '../UIControls/Shortcut'

export default function ShortcutsArea({ task, isActiveOrganizeMode, accessGranted, projectId, isLocked }) {
    const loggedUserId = useSelector(state => state.loggedUser.uid)
    const showShortcuts = useSelector(state => state.showShortcuts)
    const shortcutFocusTasks = useSelector(state => state.shortcutFocusTasks)
    const activeEditMode = useSelector(state => state.activeEditMode)

    const loggedUserIsTaskOwner = loggedUserId === task.userId
    const loggedUserCanUpdateObject =
        loggedUserIsTaskOwner || !ProjectHelper.checkIfLoggedUserIsNormalUserInGuide(projectId)

    return (
        <>
            {showShortcuts && shortcutFocusTasks?.current === task.id && !isActiveOrganizeMode && !isLocked && (
                <View style={{ position: 'absolute', top: 0, right: 14 }}>
                    <Shortcut text={'Shift+E'} />
                </View>
            )}
            {loggedUserCanUpdateObject &&
                showShortcuts &&
                shortcutFocusTasks?.current === task.id &&
                accessGranted &&
                !isActiveOrganizeMode &&
                !isLocked && (
                    <View style={{ position: 'absolute', top: 0, left: 14 }}>
                        <Shortcut text={'Shift+D'} />
                    </View>
                )}
            {showShortcuts && activeEditMode && shortcutFocusTasks?.prev === task.id && (
                <View style={{ position: 'absolute', top: 0, right: 12 }}>
                    <Shortcut
                        text={<Icon name={'arrow-up-symbol'} color={'#ffffff'} size={12} />}
                        containerStyle={{ paddingHorizontal: 2 }}
                        custom={true}
                    />
                </View>
            )}
            {showShortcuts && activeEditMode && shortcutFocusTasks?.next === task.id && (
                <View style={{ position: 'absolute', top: 0, right: 12 }}>
                    <Shortcut
                        text={<Icon name={'arrow-down-symbol'} color={'#ffffff'} size={12} />}
                        containerStyle={{ paddingHorizontal: 2 }}
                        custom={true}
                    />
                </View>
            )}
        </>
    )
}
