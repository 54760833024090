import React, { useState, useRef } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'

import VariableModal from '../VariableModal/VariableModal'
import TaskModal, { TASK_TYPE_PROMPT } from './TaskModal'
import { updatePreConfigTask, uploadNewPreConfigTask } from '../../../../utils/backends/Assistants/assistantsFirestore'
import { CONFIRM_POPUP_TRIGGER_DELETE_PRE_CONFIG_TASK } from '../../ConfirmPopup'
import { showConfirmPopup } from '../../../../redux/actions'

export default function PreConfigTaskModal({ disabled, projectId, closeModal, adding, assistantId, task }) {
    const dispatch = useDispatch()
    const [name, setName] = useState(task ? task.name : '')
    const [prompt, setPrompt] = useState(task ? task.prompt : '')
    const [link, setLink] = useState(task ? task.link : '')
    const [taskType, setTaskType] = useState(task ? task.type : TASK_TYPE_PROMPT)
    const [variables, setVariables] = useState(task ? task.variables : [])
    const [activeVariableIndex, setActiveVariableIndex] = useState(null)
    const [showVariableModal, setShowVariableModal] = useState(false)

    const promptInputRef = useRef()
    const linkInputRef = useRef()

    const addTask = () => {
        closeModal()
        const newTask =
            taskType === TASK_TYPE_PROMPT
                ? { name, type: taskType, prompt, variables, link: '' }
                : { name, type: taskType, prompt: '', variables: [], link }
        setTimeout(() => {
            uploadNewPreConfigTask(projectId, assistantId, newTask)
        }, 1000)
    }

    const saveTask = () => {
        const updatedTask =
            taskType === TASK_TYPE_PROMPT
                ? { ...task, name, type: taskType, prompt, variables, link: '' }
                : { ...task, name, type: taskType, prompt: '', variables: [], link }
        updatePreConfigTask(projectId, assistantId, updatedTask)
        closeModal()
    }

    const deleteTask = () => {
        dispatch(
            showConfirmPopup({
                trigger: CONFIRM_POPUP_TRIGGER_DELETE_PRE_CONFIG_TASK,
                object: {
                    taskId: task.id,
                    projectId: projectId,
                    assistantId: assistantId,
                },
            })
        )
        closeModal()
    }

    const addVariable = variable => {
        setVariables(state => [...state, variable])
        setTimeout(() => {
            const newPrompt = prompt.trim() + ` $${variable.name}`
            const editor = promptInputRef.current.getEditor()
            editor.setText(newPrompt, 'user')
        })
    }

    const removeVariable = variableIndex => {
        setVariables(state => state.toSpliced(variableIndex, 1))
        const newPrompt = prompt.trim().replaceAll(`$${variables[variableIndex].name}`, ``)
        promptInputRef.current.getEditor().setText(newPrompt, 'user')
    }

    const updateVariable = (variable, variableIndex) => {
        setVariables(state => {
            const newState = [...state]
            newState[variableIndex] = variable
            return newState
        })
        setTimeout(() => {
            const newPrompt = prompt.trim().replaceAll(`$${variables[variableIndex].name}`, `$${variable.name}`)
            promptInputRef.current.getEditor().setText(newPrompt, 'user')
        })
    }

    const openVariableModal = variableIndex => {
        setShowVariableModal(true)
        setActiveVariableIndex(variableIndex)
    }

    const closeVariableModal = () => {
        setShowVariableModal(false)
        setActiveVariableIndex(null)
    }

    return (
        <View>
            {showVariableModal ? (
                <VariableModal
                    closeModal={closeVariableModal}
                    addVariable={addVariable}
                    updateVariable={updateVariable}
                    variableIndex={activeVariableIndex}
                    variables={variables}
                    disabled={disabled}
                />
            ) : (
                <TaskModal
                    closeModal={closeModal}
                    adding={adding}
                    name={name}
                    setName={setName}
                    prompt={prompt}
                    setPrompt={setPrompt}
                    variables={variables}
                    openVariableModal={openVariableModal}
                    removeVariable={removeVariable}
                    addTask={addTask}
                    saveTask={saveTask}
                    deleteTask={deleteTask}
                    disabled={disabled}
                    promptInputRef={promptInputRef}
                    linkInputRef={linkInputRef}
                    link={link}
                    setLink={setLink}
                    taskType={taskType}
                    setTaskType={setTaskType}
                />
            )}
        </View>
    )
}
