import Colors from '../Themes/Colors'

export const TEXT_COLORS = [
    { color: '#04142F', name: 'None', shortcut: '0' },
    { color: '#091540', name: 'Dark blue', shortcut: '1' },
    { color: '#BD0303', name: 'Red', shortcut: '2' },
    { color: '#F58E0A', name: 'Yellow', shortcut: '3' },
    { color: '#07A873', name: 'Green', shortcut: '4' },
    { color: '#0070E0', name: 'Blue', shortcut: '5' },
    { color: '#702EE6', name: 'Violet', shortcut: '6' },
]

export const BACKGROUND_COLORS = [
    { color: '#FFFFFF', name: 'None', shortcut: '0' },
    { color: '#B7BDC8', name: 'Light gray', shortcut: '1' },
    { color: '#FFC7C7', name: 'Light red', shortcut: '2' },
    { color: '#FFE6C7', name: 'Light yellow', shortcut: '3' },
    { color: '#ADF0D9', name: 'Light green', shortcut: '4' },
    { color: '#C7E3FF', name: 'Light blue', shortcut: '5' },
    { color: '#DBC7FF', name: 'Light violet', shortcut: '6' },
]

export const GOAL_HIGHLIGHT_COLORS_LIGHT_VERSIONS_MAP = {
    [Colors.Grey300]: Colors.Grey200,
    [Colors.Text04]: Colors.Grey350,
    [Colors.UtilityRed125]: Colors.UtilityRed100,
    [Colors.UtilityYellow125]: Colors.UtilityYellow100,
    [Colors.UtilityGreen125]: Colors.UtilityGreen100,
    [Colors.UtilityBlue125]: Colors.UtilityBlue100,
    [Colors.UtilityViolet125]: Colors.UtilityViolet100,
    [Colors.Marker_PrimaryBlue_03]: Colors.Marker_PrimaryBlue_04,
    [Colors.Marker_Blue_03]: Colors.Marker_Blue_04,
    [Colors.Marker_Red_03]: Colors.Marker_Red_04,
    [Colors.Marker_Purple_03]: Colors.Marker_Purple_04,
    [Colors.Marker_Green_03]: Colors.Marker_Green_04,
    [Colors.Marker_Pink_03]: Colors.Marker_Pink_04,
    [Colors.Marker_Orange_03]: Colors.Marker_Orange_04,
    [Colors.Marker_Yellow_03]: Colors.Marker_Yellow_04,
    [Colors.Marker_Pelorous_03]: Colors.Marker_Pelorous_04,
    [Colors.Marker_Lime_03]: Colors.Marker_Lime_04,
    [Colors.Marker_Violet_03]: Colors.Marker_Violet_04,
}
