import React, { useEffect, useState } from 'react'
import v4 from 'uuid/v4'
import { useSelector } from 'react-redux'

import { watchChat } from '../../../utils/backends/Chats/chatsFirestore'
import { unwatch } from '../../../utils/backends/firestore'
import LastAssistantCommentWrapper from './LastAssistantCommentWrapper'
import { watchComments } from '../../../utils/backends/Chats/chatsComments'

export default function LastAssistantCommentContainer() {
    const defaultAssistantId = useSelector(state => state.defaultAssistant.uid)
    const projectId = useSelector(state => state.loggedUser.lastAssistantCommentData.projectId)
    const objectType = useSelector(state => state.loggedUser.lastAssistantCommentData.objectType)
    const objectId = useSelector(state => state.loggedUser.lastAssistantCommentData.objectId)
    const [commentText, setCommentText] = useState(null)
    const [chat, setChat] = useState(null)

    const updateComment = comments => {
        const comment = comments[0]
        setCommentText(comment ? comment.commentText : null)
    }

    useEffect(() => {
        if (projectId && objectType && objectId) {
            const commentWatcherKey = v4()

            watchComments(projectId, objectType, objectId, commentWatcherKey, 1, updateComment)

            const chatWatcherKey = v4()
            watchChat(projectId, objectId, chatWatcherKey, setChat)

            return () => {
                unwatch(commentWatcherKey)
                unwatch(chatWatcherKey)
            }
        } else {
            setCommentText(null)
        }
    }, [projectId, objectType, objectId])

    if (commentText === null || commentText === undefined || !chat) return null

    return (
        <LastAssistantCommentWrapper
            projectId={projectId}
            objectId={objectId}
            objectType={objectType}
            objectName={chat.title}
            assistantId={chat.assistantId || defaultAssistantId}
            commentText={commentText}
        />
    )
}
