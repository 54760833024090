import React from 'react'

import { colors } from '../../styles/global'
import store from '../../../redux/store'
import { showConfirmPopup } from '../../../redux/actions'
import { CONFIRM_POPUP_TRIGGER_DELETE_ASSISTANT } from '../../UIComponents/ConfirmPopup'
import Button from '../../UIControls/Button'
import { translate } from '../../../i18n/TranslationService'

export default function DeleteAssistantButton({ isGlobalAsisstant, projectId, assistant }) {
    const onPress = () => {
        store.dispatch(
            showConfirmPopup({
                trigger: CONFIRM_POPUP_TRIGGER_DELETE_ASSISTANT,
                object: { projectId, isGlobalAsisstant, assistant },
            })
        )
    }

    return (
        <Button
            icon={'trash-2'}
            title={translate('Delete Assistant')}
            type={'ghost'}
            iconColor={colors.UtilityRed200}
            titleStyle={{ color: colors.UtilityRed200 }}
            buttonStyle={{ borderColor: colors.UtilityRed200, borderWidth: 2 }}
            onPress={onPress}
            accessible={false}
        />
    )
}
