import Colors from '../../../Themes/Colors'

const ThemeColors = {
    RootView: {
        // NotesItem component
        StickyItem: {
            containerSticky: _color => ({
                backgroundColor: Colors.UtilityYellow100,
            }),
        }
    },
}

export default ThemeColors
