import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { difference } from 'lodash'

import { watchProjectChatNotifications } from '../../utils/InitialLoad/initialLoadHelper'

export default function ObservedForWatchOutsideNewProjectsChats() {
    const realTemplateProjectIds = useSelector(state => state.loggedUser.realTemplateProjectIds)
    const realGuideProjectIds = useSelector(state => state.loggedUser.realGuideProjectIds)
    const [watchedTemplateProjectIds, setWatchedTemplateProjectIds] = useState(realTemplateProjectIds)
    const [watchedRealGuideProjectIds, setWatchedRealGuideProjectIds] = useState(realGuideProjectIds)

    useEffect(() => {
        const newTemplateIds = difference(realTemplateProjectIds, watchedTemplateProjectIds)
        if (newTemplateIds.length > 0) {
            setWatchedTemplateProjectIds(realTemplateProjectIds)
            newTemplateIds.forEach(projectId => {
                watchProjectChatNotifications(projectId)
            })
        }
    }, [realTemplateProjectIds])

    useEffect(() => {
        const newGuideIds = difference(realGuideProjectIds, watchedRealGuideProjectIds)
        if (newGuideIds.length > 0) {
            setWatchedRealGuideProjectIds(realGuideProjectIds)
            newGuideIds.forEach(projectId => {
                watchProjectChatNotifications(projectId)
            })
        }
    }, [realGuideProjectIds])

    return <View />
}
