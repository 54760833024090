import React from 'react'
import { StyleSheet, View } from 'react-native'

import PrivacyButton from '../../UIComponents/FloatModals/PrivacyModal/PrivacyButton'
import HighlightButton from '../../UIComponents/FloatModals/HighlightColorModal/HighlightButton'
import TaskMoreButton from '../../UIComponents/FloatModals/MorePopupsOfEditModals/Tasks/TaskMoreButton'
import EstimationButton from '../../UIControls/EstimationButton'
import DueDateButton from '../../UIControls/DueDateButton'
import CommentButton from '../../UIControls/CommentButton'
import FollowUpButton from '../../UIControls/FollowUpButton'
import { FEED_PUBLIC_FOR_ALL, FEED_TASK_OBJECT_TYPE } from '../../Feeds/Utils/FeedsConstants'
import { translate } from '../../../i18n/TranslationService'
import { useSelector } from 'react-redux'
import OpenDvButton from './OpenDvButton'

export default function SecondaryButtonsArea({
    tmpTask,
    hasName,
    showButtonSpace,
    isSuggestedTask,
    adding,
    projectId,
    isObservedTask,
    isToReviewTask,
    accessGranted,
    loggedUserCanUpdateObject,
    isAssistant,
    onOpenDetailedView,
    setDueDateBeforeSave,
    setToBacklogBeforeSave,
    onDismissPopup,
    setPrivacyBeforeSave,
    setCommentBeforeSave,
    setHighlightBeforeSave,
    setEstimationBeforeSave,
    setFollowUpBeforeSave,
    setDescriptionBeforeSave,
    setParentGoalBeforeSave,
    dismissEditMode,
    setRecurrenceBeforeSave,
    setTempAutoEstimation,
    isPending,
    parentInTaskOutOfOpen,
}) {
    const smallScreen = useSelector(state => state.smallScreen)
    const loggedUserId = useSelector(state => state.loggedUser.uid)
    const taskViewToggleSection = useSelector(state => state.taskViewToggleSection)

    const buttonItemStyle = { marginRight: smallScreen ? 8 : 4 }

    return (
        <View style={localStyles.container}>
            <OpenDvButton
                showButtonSpace={showButtonSpace}
                onOpenDetailedView={onOpenDetailedView}
                buttonItemStyle={buttonItemStyle}
                projectId={projectId}
                disabled={adding && !hasName}
            />

            {loggedUserCanUpdateObject &&
                !tmpTask.done &&
                taskViewToggleSection !== 'Workflow' &&
                !tmpTask.gmailData && (
                    <DueDateButton
                        task={tmpTask}
                        projectId={projectId}
                        style={buttonItemStyle}
                        disabled={!hasName || !accessGranted}
                        inEditTask={true}
                        saveDueDateBeforeSaveTask={setDueDateBeforeSave}
                        setToBacklogBeforeSaveTask={setToBacklogBeforeSave}
                        onDismissPopup={onDismissPopup}
                        shortcutText={'R'}
                        isObservedTask={isObservedTask}
                    />
                )}

            {adding ? (
                <PrivacyButton
                    projectId={projectId}
                    object={tmpTask}
                    objectType={FEED_TASK_OBJECT_TYPE}
                    disabled={!hasName || !accessGranted}
                    savePrivacyBeforeSaveObject={setPrivacyBeforeSave}
                    inEditComponent={true}
                    style={buttonItemStyle}
                    shortcutText={'P'}
                />
            ) : (
                <CommentButton
                    style={buttonItemStyle}
                    task={tmpTask}
                    disabled={!hasName}
                    inEditTask
                    saveCommentBeforeSaveTask={setCommentBeforeSave}
                    projectId={projectId}
                    onDismissPopup={onDismissPopup}
                    shortcutText={'C'}
                />
            )}

            {loggedUserCanUpdateObject && (
                <HighlightButton
                    projectId={projectId}
                    object={tmpTask}
                    objectType={FEED_TASK_OBJECT_TYPE}
                    disabled={!hasName || !accessGranted}
                    saveHighlightBeforeSaveObject={setHighlightBeforeSave}
                    inEditComponent={true}
                    style={buttonItemStyle}
                    shortcutText={'H'}
                />
            )}

            {!parentInTaskOutOfOpen &&
                loggedUserCanUpdateObject &&
                !isPending &&
                !tmpTask.inDone &&
                !isSuggestedTask &&
                !isAssistant && (
                    <EstimationButton
                        task={tmpTask}
                        projectId={projectId}
                        style={buttonItemStyle}
                        disabled={!hasName || !accessGranted}
                        setEstimationFn={setEstimationBeforeSave}
                        onDismissPopup={onDismissPopup}
                        shortcutText={'E'}
                        isToReviewTask={isToReviewTask}
                        isObservedTask={isObservedTask}
                        editing={!adding}
                        setTempAutoEstimation={setTempAutoEstimation}
                        isPending={isPending}
                    />
                )}

            {!adding && tmpTask.done && loggedUserCanUpdateObject && !isAssistant && (
                <FollowUpButton
                    buttonText={translate('Follow up')}
                    task={tmpTask}
                    projectId={projectId}
                    style={buttonItemStyle}
                    disabled={!hasName || !accessGranted}
                    inEditTask={true}
                    saveDateBeforeSaveTask={setFollowUpBeforeSave}
                    onDismissPopup={onDismissPopup}
                    shortcutText={'J'}
                />
            )}

            {loggedUserCanUpdateObject && (
                <TaskMoreButton
                    editing={!adding}
                    formType={adding ? 'new' : 'edit'}
                    projectId={projectId}
                    task={
                        !tmpTask.parentGoalIsPublicFor ||
                        tmpTask.parentGoalIsPublicFor.includes(FEED_PUBLIC_FOR_ALL) ||
                        tmpTask.parentGoalIsPublicFor.includes(loggedUserId)
                            ? tmpTask
                            : { ...tmpTask, parentGoalId: null, parentGoalIsPublicFor: null }
                    }
                    buttonStyle={buttonItemStyle}
                    saveDescription={setDescriptionBeforeSave}
                    updateActiveGoal={setParentGoalBeforeSave}
                    dismissEditMode={dismissEditMode}
                    savePrivacyBeforeSaveObject={setPrivacyBeforeSave}
                    saveRecurrenceBeforeSaveTask={setRecurrenceBeforeSave}
                    disabled={!hasName || !accessGranted}
                    isSuggestedTask={isSuggestedTask}
                    setEstimationBeforeSave={setEstimationBeforeSave}
                    isAssistant={isAssistant}
                    setTempAutoEstimation={setTempAutoEstimation}
                />
            )}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
})
